<template>
  <div class="search-form">
    <el-form ref="form"
             :model="form"
             label-position="right"
             label-width="100px">
      <el-row style="width:100%">
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="告警标题">
            <el-input class="inputWid"
                      @keyup.enter.native="confirm"
                      v-model="form.title"
                      clearable
                      placeholder="请输入告警标题"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="监控指标">
            <el-select class="inputWid"
                       @keyup.enter.native="confirm"
                       v-model="form.targetId">
              <el-option v-for="item in options"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="扫描间隔">
            <el-select class="inputWid"
                       @keyup.enter.native="confirm"
                       v-model="form.time">
              <el-option v-for="item in timeList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="width:100%">
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="告警邮箱">
            <el-input class="inputWid"
                      @keyup.enter.native="confirm"
                      v-model="form.email"
                      clearable
                      placeholder="请输入告警邮箱"></el-input>
          </el-form-item>
        </el-col>
        <el-col class="search-btn"
                :xs="24"
                :sm="24"
                :md="16"
                :lg="16"
                :xl="16">
          <el-form-item>
            <el-button type="primary"
                       @click="confirm">查询</el-button>
            <el-button class="form-item-Left"
                       @click="rest">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      timeList: [
        {
          value: null,
          label: '全部'
        },
        {
          value: 5,
          label: '5S'
        },
        {
          value: 10,
          label: '10S'
        },
        {
          value: 15,
          label: '15S'
        },
        {
          value: 30,
          label: '30S'
        },
        {
          value: 60,
          label: '1m'
        },
        {
          value: 300,
          label: '5m'
        },
        {
          value: 600,
          label: '10m'
        },
        {
          value: 900,
          label: '15m'
        },
        {
          value: 1800,
          label: '30m'
        },
        {
          value: 3600,
          label: '1h'
        }
      ],
      options: []
    }
  },
  mounted() {
    this.getTarget()
  },
  methods: {
    // 获取性能指标数组
    async getTarget() {
      let { data } = await this.$api.warning.target()
      if (data) {
        let array = [
          {
            value: null,
            label: '全部'
          }
        ]
        for (let item of data) {
          let obj = {}
          obj.value = item.value
          obj.label = item.label
          array.push(obj)
        }
        this.options = JSON.parse(JSON.stringify(array))
      }
    },

    rest() {
      this.form.title = null
      this.form.targetId = null
      this.form.time = null
      this.form.email = null
      this.confirm()
    },
    confirm() {
      let _form = {
        title: this.form.title ? this.trim(this.form.title) : null,
        targetId: this.form.targetId,
        time: this.form.time,
        email: this.form.email ? this.trim(this.form.email) : null
      }
      this.$emit('search', _form)
    },
    trim(s) {
      // 去除目标字符串左右两边的空白字符
      return s.replace(/(^\s*)|(\s*$)/g, '')
    }
  }
}
</script>
