<template>
  <div>
    <table-list :headData="headData"
                :columns="columns"
                :data="this.list"
                fit
                stripe
                :tableOption="{size: 'mini'}"
                :loading="loading"
                :pageData="pageData"
                @handlePageChange="handlePageChange"
                @handleSizeChange="handleSizeChange">
      <!-- 告警ID -->
      <!-- <template #alertId="{row}">
        <div class="div-row shenglue">
          <span style="width:100px"
                class="shenglue">{{row.alertId}}</span>
          <i class="el-icon-document-copy"
             @click="copy($event, row.alertId)"></i>
        </div>
      </template> -->

      <!-- 告警标题 -->
      <template #title="{row}">
          <popover v-if="row.title"
                   :width="300"
                   :value="row.title"
                   :showDict="false"
                   :isSubStr="false"
                   :divClass="'shenglue-1'"></popover>
        <span v-else>无</span>
      </template>

      <!-- 扫描间隔 -->
      <template #time="{row}">
        <span v-if="row.time">{{getTimeTo(row.time)}}</span>
        <span v-else>无</span>
      </template>

      <!-- 告警邮箱 -->
      <template #email="{row}">
          <popover v-if="row.email"
                   :width="300"
                   :value="row.email"
                   :showDict="false"
                   :isSubStr="false"
                   :divClass="'shenglue-1'"></popover>
        <span v-else>无</span>
      </template>

    </table-list>
    <!-- Dialog -->
    <el-dialog :title="title"
               width="55%"
               :visible.sync="dialogVisible">
      <pre v-if="info"
           v-html="info"></pre>
      <div v-else>暂无数据</div>
    </el-dialog>

    <!-- 新增表单 -->
    <el-dialog class="tableList-addForm" :close-on-click-modal="false"
               width="700px"
               :title="dialogTitle"
               :visible.sync="dialogShow" @close="closeDialog('form')">
      <el-form label-position="right"
               ref="form"
               :model="form"
               :rules="rules"
               label-width="120px">
        <el-form-item label="告警标题"
                      prop="title">
          <el-input class="el-input-1"
                    v-model="form.title"
                    clearable
                    placeholder="请输入"
                    maxlength="50"
                    type="text"
                    show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="监控指标"
                      prop="targetId">
          <el-select class="el-input-1"
                     v-model="form.targetId"
                     @change="handleSelectLabel"
                     clearable>
            <el-option v-for="item in options"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 节点离线 -->
        <!-- <el-form-item v-if="form.targetId === '2'"
                      label="触发条件"
                      prop="target">
          <el-input class="el-input-1"
                    v-model="form.target"
                    clearable
                    placeholder="请输入IP和端口"
                    type="text"></el-input>
        </el-form-item> -->
        <!-- 硬件指标 -->
        <el-form-item v-if="form.targetId === '3' || form.targetId === '4' || form.targetId === '5' || form.targetId === '6'"
                      label="触发条件"
                      prop="target">
          <div>
            <el-select class="el-input-3"
                       v-model="form.eqVal"
                       @change="handleEq()"
                       clearable>
              <el-option v-for="item in eqList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
            <el-input class="el-input-2"
                      v-model="form.target"
                      clearable
                      placeholder="请输入">
            </el-input>
          </div>
        </el-form-item>
        <!-- 链上指标 -->
        <el-form-item v-if="form.targetId === '2' || form.targetId === '7' || form.targetId === '8' || form.targetId === '9'"
                      label="触发条件"
                      prop="target">
          <div>
            <el-select class="el-input-5"
                       v-model="form.state"
                       placeholder="网络类型"
                       @change="handleTarget()"
                       clearable>
              <el-option v-for="item in stateOpt"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
            <el-select class="el-input-6"
                       v-model="form.shard"
                       placeholder="分片名"
                       @change="handleTarget()"
                       clearable>
              <el-option v-for="item in shardList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
        </el-form-item>

        <el-form-item label="扫描间隔"
                      prop="time">
          <el-select class="el-input-1"
                     v-model="form.time"
                     clearable>
            <el-option v-for="item in timeList"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="告警邮箱"
                      prop="email">
          <el-input class="el-input-1"
                    v-model="form.email"
                    clearable
                    placeholder="请输入"
                    type="text"></el-input>
        </el-form-item>
        <el-form-item label="告警模板"
                      prop="alertTmplId">
          <el-select class="el-input-1"
                     v-model="form.alertTmplId"
                     clearable>
            <el-option v-for="item in templList"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <div class="tableList-form-buttonBox">
        <el-button size="medium"
                   @click="submitForm('form')"
                   type="primary"
                   v-loading="isSubmitting">提交 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import TableList from '@/components/TableList'
import timeFormat from '@/utils/timeFormat.js'
import tableObj from '../component/tableData.js'
import regeExp from '@/utils/regeExp.js'
import Popover from '@/components/Popover'
import {
  activeArray
} from '@/utils/localStorage.js'
export default {
  props: {
    datas: {
      type: Object,
      default: () => {}
    },
    dataList: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    datas: {
      handler() {
        this.pageData.currentPage = 1
        this.getData(1)
      },
      deep: false,
      immediate: true
    }
  },
  components: {
    TableList,
    Popover
  },
  data() {
    // 触发条件自定义验证规则
    var validateTarget = (rules, value, callback) => {
      if (value === '') {
        callback(new Error('触发条件不能为空'))
      } else {
        // if (this.form.targetId === '2') {
        //   if (!regeExp.ipPort(value)) {
        //     callback(new Error('节点地址格式错误'))
        //   } else {
        //     callback()
        //   }
        // } else
        if (
          this.form.targetId === '3' ||
          this.form.targetId === '4' ||
          this.form.targetId === '5' ||
          this.form.targetId === '6'
        ) {
          if (this.form.eqVal === '=') {
            if (!regeExp.number1(value)) {
              callback(new Error('只能输入0~100的整数'))
            } else {
              callback()
            }
          } else if (this.form.eqVal === '>') {
            if (!regeExp.number2(value)) {
              callback(new Error('只能输入0~99的整数'))
            } else {
              callback()
            }
          } else if (this.form.eqVal === '<') {
            if (!regeExp.number3(value)) {
              callback(new Error('只能输入1~100的整数'))
            } else {
              callback()
            }
          }
        } else if (
          this.form.targetId === '2' ||
          this.form.targetId === '7' ||
          this.form.targetId === '8' ||
          this.form.targetId === '9'
        ) {
          if (this.form.state && this.form.shard) {
            this.form.target = this.form.state + ':' + this.form.shard
            callback()
          } else {
            callback(new Error('网络类型和分片名不能为空'))
          }
        }
      }
    }
    var emailValidate = (rules, value, callback) => {
      if (value === '') {
        callback(new Error('告警邮箱不能为空'))
      } else {
        var re = /^[-\w+.%]+@[\w-.]+\.[A-Za-z]{2,4}$/
        var items = value.split(',')
        if (
          items.length <= 5 &&
          items.filter(function (x) {
            return re.test(x)
          }).length === items.length
        ) {
          callback()
        } else {
          callback(new Error('请输入1~5个正确的邮箱，并用逗号隔开'))
        }
      }
    }
    return {
      loading: false,
      title: '',
      dialogVisible: false,
      isSubmitting: false,
      dialogTitle: '',
      dialogShow: false,
      form: {
        // startTime: '',
        alertId: '', // 告警表ID
        title: '', // 告警标题
        targetId: '', // 监控指标
        targetName: '', // 监控指标名称
        target: '', // 触发条件
        time: '', // 扫描间隔
        email: '', // 告警邮箱
        alertTmplId: '', // 告警模板
        eqVal: '>',
        state: '',
        shard: ''
      },
      rules: {
        title: [
          { required: true, message: '请输入告警标题', trigger: 'change' },
          {
            min: 1,
            max: 50,
            message: '长度在 1 到 50 个字符',
            trigger: 'change'
          }
        ],
        targetId: {
          required: true,
          message: '请选择监控指标',
          trigger: 'change'
        },
        target: {
          type: 'string',
          required: true,
          trigger: 'blur',
          validator: validateTarget
        },
        time: { required: true, message: '请选择扫描间隔', trigger: 'change' },
        email: {
          type: 'string',
          required: true,
          trigger: 'blur',
          validator: emailValidate
        },
        alertTmplId: {
          required: true,
          message: '请选择告警模板',
          trigger: 'change'
        }
      },
      columns: tableObj.tableData,
      headData: tableObj.headData,
      info: '',
      value: '', // 性能指标
      list: [], // 列表
      body: {},
      pageData: {
        size: 10, // 每页的数据量
        pageSize: 10, // 每页的条数跳转页面
        pageSizes: [10, 20, 50, 100], // 每页的条数跳转页面
        total: 0, // 总条数
        layout: 'prev, pager, next, total, sizes, jumper, slot',
        currentPage: 1 // 当前页
      }, // 分页配置
      options: [], // 监控指标数组
      shardList: [], // 分片名数组
      templList: [], // 告警模板数组
      timeList: tableObj.options.timeList, // 扫描间隔数组
      eqList: tableObj.options.eqList, // 条件数组
      stateOpt: tableObj.options.stateOpt //  网络类型数组
    }
  },
  created() {},
  mounted() {
    this.getTarget()
    this.getAliases()
    this.getTmplList()
  },
  methods: {
    getActiveArray(value) {
      return activeArray(value)
    },
    // 清空校验
    closeDialog(formData) {
      this.$refs[formData].resetFields()
    },
    getTimeTo(time) {
      for (let item of this.timeList) {
        if (time === item.value) {
          return item.label
        } else {
          return time + 'S'
        }
      }
    },
    // 分片名数组
    async getAliases() {
      let { data } = await this.$api.home.shard()
      if (data) {
        let array = []
        for (let item of data) {
          let obj = {}
          obj.value = item
          obj.label = item
          array.push(obj)
        }
        this.shardList = array
      }
    },
    // 获取告警模板
    async getTmplList() {
      const { data } = await this.$api.warning.tmpl()
      // console.log(data)
      this.templList = data
    },
    // 获取性能指标数组
    getTarget() {
      this.$api.warning.target().then((res) => {
        if (res.data) {
          this.options = res.data
          // console.log('res:', this.options)
        }
      })
    },
    // 清空输入值
    handleEq() {
      this.form.target = ''
    },
    // 为触发条件赋值
    handleTarget() {
      if (this.form.state && this.form.shard) {
        this.form.target = this.form.state + ':' + this.form.shard
        // console.log('触发条件:', this.form.target)
      }
    },
    // 获取监控指标名称
    handleSelectLabel(val) {
      this.form.target = ''
      this.form.targetName = val
        ? this.options.find((ele) => ele.value === val).label
        : ''
    },
    // 编辑
    async handleEditor(alertId) {
      this.dialogTitle = '修改告警'
      this.dialogShow = true
      // 查询单条告警
      let options = {
        alertId: alertId
      }
      const { data } = await this.$api.warning.getAlert(options)
      // 回显表单
      if (data) {
        this.form.alertId = data.alertId
        this.form.alertTmplId = data.alertTmplId
        this.form.email = data.email
        this.form.target = data.target
        this.form.targetId = data.targetId
        this.form.targetName = data.targetName
        this.form.time = data.time
        this.form.title = data.title
        if (
          data.targetId === '3' ||
          data.targetId === '4' ||
          data.targetId === '5' ||
          data.targetId === '6'
        ) {
          this.form.eqVal = data.target.substring(0, 1)
          this.form.target = data.target.substring(1, data.target.length)
        } else if (
          data.targetId === '2' ||
          data.targetId === '7' ||
          data.targetId === '8' ||
          data.targetId === '9'
        ) {
          let array = data.target.split(':')
          this.form.state = array[0]
          this.form.shard = array[1]
        }
      }
    },
    // 点击提交按钮,触发提交事件
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.isSubmitting) {
            return
          }
          // 判断是否新增
          if (!this.form.alertId) {
            // 添加告警
            if (!this.form.shard && this.form.targetId !== '2') {
              var _form = this.form
              _form.target = _form.eqVal + _form.target
              this.form = _form
            }
            this.isSubmitting = true
            let options = this.form
            // console.log('options:', options)
            this.$api.warning.save(options).then((res) => {
              // console.log(res)
              this.dialogShow = false
              if (res.code === 200) {
                this.$message.success('添加成功')
                this.getData(1)
              } else {
                this.$message.error(res.message)
              }
              setTimeout(() => {
                this.isSubmitting = false
              }, 1000)
            })
          } else {
            // 修改告警
            if (!this.form.shard && this.form.targetId !== '2') {
              var form = this.form
              form.target = form.eqVal + form.target
              this.form = form
            }
            this.isSubmitting = true
            let options = this.form
            // console.log('options:', options)
            this.$api.warning.update(options).then((res) => {
              console.log(res)
              this.dialogShow = false
              if (res.code === 200) {
                this.$message.success('修改成功')
                this.getData(1)
              } else {
                this.$message.error(res.message)
              }
              setTimeout(() => {
                this.isSubmitting = false
              }, 1000)
            })
          }
        }
      })
    },
    // 打开详情弹窗
    handleOpen(value) {
      this.title = '触发条件'
      // console.log(value)
      this.info = this.syntaxHighlight(JSON.parse(value))
      this.dialogVisible = true
    },
    syntaxHighlight(_json) {
      var json = {}
      if (typeof _json !== 'string') {
        for (let item in _json) {
          var value = _json[item]
          if (value == null || value === undefined) {
            continue
          }
          json[item] = value
        }
        json = JSON.stringify(json, undefined, 2)
      }
      json = json.replace(/&/g, '&').replace(/</g, '<').replace(/>/g, '>')
      return json.replace(
        // eslint-disable-next-line no-useless-escape
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
        function (match) {
          var cls = 'number'
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = 'key'
            } else {
              cls = 'string'
            }
          } else if (/true|false/.test(match)) {
            cls = 'boolean'
          } else if (/null/.test(match)) {
            cls = 'null'
          }
          return '<span class="' + cls + '">' + match + '</span>'
        }
      )
    },
    // 一键复制
    copy(e, text) {
      console.log(e, text)
      const clipboard = new Clipboard(e.target, { text: () => text })
      this.copyLoading = true
      clipboard.on('success', (e) => {
        // 判断消息提示条数
        if (document.getElementsByClassName('el-message').length > 2) return
        this.$message({ type: 'success', message: '复制成功' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message({ type: 'waning', message: '该浏览器不支持自动复制' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.onClick(e)
    },
    // 跳转详情页
    handleDetail(value) {
      this.$router.push({
        name: 'message',
        query: { alertId: value }
      })
    },
    // 时间转换
    tf(time, format) {
      return timeFormat(time, format)
    },
    async getData(currentPage) {
      this.loading = true
      let option = {
        page: currentPage - 1,
        size: this.pageData.size,
        title: this.datas.title,
        targetId: this.datas.targetId,
        time: this.datas.time,
        email: this.datas.email
      }
      let { data, code } = await this.$api.warning.listAlert(option)
      if (code === 200) {
        this.pageData.total = data.total
        this.list = data.content
      }
      this.loading = false
    },
    handlePageChange(goPage) {
      this.pageData.currentPage = goPage
      this.getData(goPage)
    },
    // 配置每页条数
    handleSizeChange(number) {
      this.pageData.currentPage = 1
      this.pageData.size = number
      this.pageData.pageSize = number
      this.getData(1)
    }
  }
}
</script>

<style lang="scss" scoped>
.table-row:hover {
  text-decoration: underline;
  color: $--color-primary;
}
// 不换行省略
.shenglue {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
// js样式
/deep/ pre {
  background: rgb(230, 230, 230);
  padding: 10px;
  margin: 10px;
  font-size: 16px;
  overflow-x: scroll;
  .string {
    color: green;
  }
  .number {
    color: darkorange;
  }
  .boolean {
    color: blue;
  }
  .null {
    color: magenta;
  }
  .key {
    color: red;
  }
}

.tableList-addForm {
  /deep/.el-dialog {
    max-width: 700px;
    .el-dialog__body {
      padding: 30px 80px 50px;
      .tableList-form-buttonBox {
        text-align: center;
      }
    }
    .el-input-1 {
      width: 460px !important;
    }
    .el-input-2 {
      width: 360px !important;
      margin-left: 10px;
    }
    .el-input-3 {
      width: 90px !important;
    }
    // .el-input-4 {
    //   width: 200px !important;
    //   margin-left:10px;
    // }
    .el-input-5 {
      width: 220px !important;
    }
    .el-input-6 {
      width: 220px !important;
      margin-left: 20px;
    }
  }
}
/deep/.tableList-addForm .el-dialog .el-dialog__body .tableList-form-buttonBox {
  text-align: right;
  margin-top: 16px;
}
</style>
