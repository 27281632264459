// 是否包含非法字符
const passReg = (val) => {
  return /[^\w!@#$%_.?]+/g.test(val)
}
// 是否包含数字
const numReg = (val) => {
  return /[0-9]+/g.test(val)
}
// 是否包含a-zA-Z
const letterReg = (val) => {
  return /[a-zA-Z]+/g.test(val)
}
// 是否包含!@#$%_.?
const codeReg = (val) => {
  return /[!@#$%_.?]+/g.test(val)
}
// 手机号验证
const phoneReg = (val) => {
  return /^1[34578]\d{9}$/.test(val)
}
// 邮箱
const emailReg = (val) => {
  return /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(val)
}
// 基金备案号
const fundRecordNumReg = (val) => {
  return /^P[a-zA-Z0-9]+$/.test(val)
}

// 多个邮箱
const _emailReg = (val) => {
  // eslint-disable-next-line no-useless-escape
  return /^((([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6}\;))*(([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})))$/.test(val)
}

// IP和端口
const ipPort = (val) => {
  // eslint-disable-next-line no-useless-escape
  return /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\:([0-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-5]{2}[0-3][0-5])$/.test(val)
}
// 0~100正整数
const number1 = (val) => {
  return /^([0-9]{1,2}|100)$/.test(val)
}
// 0~99正整数
const number2 = (val) => {
  return /^([0-9]{1,2}|99)$/.test(val)
}
// 1~100正整数
const number3 = (val) => {
  return /^([1-9]{1,2}|100)$/.test(val)
}
export default {
  passReg, numReg, letterReg, codeReg, phoneReg, emailReg, fundRecordNumReg, _emailReg, ipPort, number1, number2, number3
}
