import { activeArray } from '@/utils/localStorage.js'
// 基本字段
let tableData = [
  // {
  //   label: '告警ID',
  //   prop: 'alertId',
  //   align: 'center',
  //   show: true,
  //   enableSlot: true,
  //   minWidth: 200
  // },
  {
    label: '序号',
    type: 'index',
    show: true,
    align: 'center',
    width: 80
  },
  {
    label: '告警标题',
    prop: 'title',
    show: true,
    enableSlot: true
  },
  {
    label: '监控指标',
    prop: 'targetName',
    show: true,
    width: 160
  },
  {
    label: '触发条件',
    prop: 'target',
    align: 'center',
    show: true,
    width: 160

  },
  {
    label: '扫描间隔',
    prop: 'time',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 160
  },
  {
    label: '告警邮箱',
    prop: 'email',
    show: true,
    enableSlot: true
  },
  {
    label: '操作',
    prop: 'operators',
    width: 180,
    align: 'right',
    fixed: 'right',
    children: [
      {
        label: '查看',
        icon: 'el-icon-view',
        // 控制按钮显示隐藏
        showFilter: () => {
          return true
        },
        clickEvent: (thisVue, row) => {
          thisVue.handleDetail(row.alertId)
        }
      },
      {
        label: '编辑',
        icon: 'ym-icon-bianji',
        color: '#FF8851',
        // 控制按钮显示隐藏
        showFilter: (item, row, thisVue) => {
          return activeArray('/monitor/update-alert')
        },
        clickEvent: (thisVue, row) => {
          thisVue.handleEditor(row.alertId)
        }
      }
    ]
  }
]

// 新增表单配置
let addForm = [
  {
    showbutton: true,
    items: {
      title: {
        label: '告警标题',
        type: 'text',
        clearable: true,
        placeholder: '请输入',
        showWordLimit: true,
        maxlength: '50',
        rules: [
          { required: true, message: '请输入告警标题', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ]
      },
      targetId: {
        label: '监控指标',
        type: 'select',
        clearable: true,
        children: [],
        rules: [
          { required: true, message: '请选择监控指标', trigger: 'change' },
          {
            validator: function (rule, value, callback) {
              console.log('value：', value)
              if (value) {
                console.log(addForm[0].items)
                return callback()
              }
            }
          }
        ]
      },
      // target: {
      //   label: '触发条件',
      //   type: 'text',
      //   clearable: true,
      //   placeholder: '请输入',
      //   rules: [
      //     { required: true, message: '请输入告警标题', trigger: 'change' }
      //   ]
      // },
      time: {
        label: '扫描间隔',
        type: 'number',
        min: 1,
        max: 9999999999,
        step: 5,
        value: 5,
        controlsPosition: 'right',
        placeholder: '请输入整数秒',
        rules: [
          { required: true, message: '请输入整数秒', trigger: 'change' }
        ]
      },
      email: {
        label: '告警邮箱',
        type: 'text',
        clearable: true,
        placeholder: '最多5个邮箱，用分号隔开',
        rules: [
          { required: true, message: '请输入告警邮箱', trigger: 'change' },
          // eslint-disable-next-line no-useless-escape
          { pattern: /^((([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6}\;))*(([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})))$/, message: '请输入1~5个正确的邮箱，用分号隔开', trigger: 'change' }
        ]
      },
      alertTmplId: {
        label: '告警模板',
        type: 'select',
        clearable: true,
        children: [],
        rules: [
          { required: true, message: '请选择告警邮箱', trigger: 'change' }
        ]
      }
    }
  }
]

// 表头图标配置
let headData = {
  title: '性能预警列表',
  items: [
    /* 新增按钮 */
    {
      style: 'button',
      label: '新增',
      icon: 'el-icon-plus',
      size: 'medium',
      type: 'primary',
      // 控制按钮显示隐藏
      showFilter: (item, thisVue) => {
        return activeArray('/monitor/save-alert')
      },
      clickEvent: (thisVue) => {
        thisVue.dialogTitle = '新增告警'
        // 清空ID
        thisVue.form.alertId = ''
        thisVue.$set(thisVue, 'dialogShow', true)
        // console.log('点击：', thisVue.form)
      }
    }
  ]
}

let options = {
  timeList: [
    {
      value: 5,
      label: '5S'
    },
    {
      value: 10,
      label: '10S'
    },
    {
      value: 15,
      label: '15S'
    },
    {
      value: 30,
      label: '30S'
    },
    {
      value: 60,
      label: '1m'
    },
    {
      value: 300,
      label: '5m'
    },
    {
      value: 600,
      label: '10m'
    },
    {
      value: 900,
      label: '15m'
    },
    {
      value: 1800,
      label: '30m'
    },
    {
      value: 3600,
      label: '1h'
    }
  ],
  eqList: [
    {
      value: '>',
      label: '大于'
    },
    {
      value: '<',
      label: '小于'
    },
    {
      value: '=',
      label: '等于'
    }
  ],
  stateOpt: [
    {
      value: 'main',
      label: '主网'
    },
    {
      value: 'test',
      label: '测试网络'
    },
    {
      value: 'dev',
      label: '开发网络'
    }
  ]

}

let tableObj = {
  'tableData': tableData,
  'headData': headData,
  'addForm': addForm,
  'options': options
}

export default tableObj
